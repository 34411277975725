.footer {
  background-color: #292929;
  width: 100vw;
  min-width: 1280px;
  margin: 0 auto;
  flex-shrink: 0;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.footer > div {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  padding: 50px 8.2% 55px 15%;

  @media (min-width: 1921px) {
    padding: 50px 160px 55px 350px;
  }

  @include vp-1279 {
    padding: 52px 4.9% 55px 6%;
  }

  @include vp-767 {
    padding: 34px 0 35px 0;
    flex-direction: column;
  }
}

.footer__logo {
  margin: 4px 10px 0 0;

  @include vp-767 {
    margin: 0 auto 10px auto;
  }
}

.footer__navigation {
  margin: 0;
}
