.feedback {
  background: linear-gradient(139.2deg, $color-default-orange 30.5%, #808080 30.5%);
  width: 100vw;
  min-width: 1280px;

  @include vp-1279 {
    min-width: 768px;
    background: #808080;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.feedback > div {
  background-image: url("../img/paper-airplane.png");
  background-position: 20% 245px;
  background-repeat: no-repeat;
  background-size: 310px auto;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  padding: 120px 7% 120px 40%;

  @media (min-width: 1921px) {
    padding: 120px 134px 120px 979px;
  }

  @media (min-width: 1280px) {
    @media (min-resolution: 2dppx) {
      background-image: url("../img/paper-airplane@2x.png");
      background-size: 310px auto;
    }
  }

  @include vp-1279 {
    padding: 47px 6.5% 100px 6.5%;
    background-image: none;
  }

  @include vp-767 {
    padding: 50px 7.81% 70px 7.81%;
  }
}

.feedback h3 {
  color: $color-default-white;
  font-size: 30px;
  line-height: 34px;
  margin: 0 0 8px 0;

  @include vp-1279 {
    margin: 0 0 6px 0;
  }

  @include vp-767 {
    font-size: 30px;
    line-height: 36px;
  }
}

.feedback p {
  color: $color-default-white;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 15px 0;

  @include vp-1279 {
    margin: 0 0 10px 0;
  }
}

.feedback__form-wrapper fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 30px;
  width: 80%;
  max-width: 780px;
  min-width: 470px;
  padding: 0;
  margin: 0;
  border: none;

  @include vp-1279 {
    grid-template-columns: 1fr;
    gap: 20px 0;
    width: 100%;
    max-width: none;
  }

  @include vp-767 {
    display: flex;
    flex-direction: column;
    min-width: 270px;
  }
}

.feedback__label-wrapper input {
  color: $color-default-white;
  border: none;
  width: 100%;
  min-width: 225px;
  height: 50px;
  background-color: rgba(4, 20, 39, 0.15);
  border-radius: 3px;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  &::placeholder {
    color: $color-default-white;
    opacity: 0.4;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  &:focus-visible ~ .feedback__checkbox-mark {
    outline: 2px solid $color-default-orange;
  }

  @include vp-767 {
    min-width: 270px;
  }
}

.feedback__label-wrapper textarea {
  border: none;
  width: 100%;
  min-width: 470px;
  min-height: 155px;
  background-color: rgba(4, 20, 39, 0.15);
  border-radius: 3px;
  padding: 22px 15px;
  font-size: 16px;
  line-height: 22px;
  color: $color-default-white;

  &::placeholder {
    color: $color-default-white;
    opacity: 0.4;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include vp-1279 {
    margin-bottom: 5px;
    padding: 30px 15px;
  }

  @include vp-767 {
    min-width: 270px;
    padding: 25px 15px;
    min-height: 180px;
    margin-bottom: 10px;
  }
}

.feedback__label-wrapper {
  position: relative;
}

.feedback__label-wrapper--textarea {
  grid-column: 1 / -1;
}

.feedback__form-wrapper button {
  display: inline-block;
  vertical-align: baseline;
  padding: 14px 20.8%;
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-default-white;
  border: none;
  background-color: $color-default-orange;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  transition: background-color $trans-default;
  grid-column: 1 / 3;

  @include vp-1279 {
    margin: -7px 0 0 0;
    padding: 16px 59px 10px 59px;
    grid-column: 1 / -1;
  }

  @include vp-767 {
    padding: 16px 59px 10px 59px;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
    outline-offset: 3px;
  }

  @include hover {
    background-color: $color-active-orange;
  }

  @include active {
    background-color: $color-active-orange;
    opacity: 0.9;
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    background-color: $color-active-orange;
    cursor: auto;
  }
}

.feedback__checkbox-mark {
  display: inline-block;
  vertical-align: baseline;
  position: absolute;
  border: 2px solid $color-default-white;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  top: 15px;
  left: 10px;
  cursor: pointer;

  @include vp-1279 {
    top: 0;
    left: 1px;
  }

  @include vp-767 {
    top: 4px;
    left: 5px;
  }
}

.feedback__label-wrapper input:checked + .feedback__checkbox-mark {
  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 7px;
    border-bottom: 2px solid $color-default-white;
    border-right: 2px solid $color-default-white;
    transform: translate(5px, 2px) rotate(45deg);
  }
}

.feedback__checkbox-text {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: $color-default-white;
  opacity: 0.9;
  padding: 0 0 0 35px;
  position: absolute;
  top: 7px;
  cursor: pointer;

  @include vp-1279 {
    padding: 0 0 0 35px;
    top: 1px;
    left: -8px;
  }

  @include vp-767 {
    top: 5px;
    left: -5px;
  }
}

.feedback__label-wrapper:hover .feedback__checkbox-text {
  opacity: 1;
}

form._sending .feedback__button {
  background-color: $color-default-orange;
}
