.header {
  width: 100vw;
  min-width: 1280px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  background-color: $color-default-white;
  z-index: 2;
  transition: top 0.8s;

  @include vp-1279 {
    position: static;
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
    position: fixed;
    top: 0;
  }
}

.header > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1920px;
  padding: 15px 4.5% 15px 8%;
  align-items: center;
  margin: 0 auto;

  @media (min-width: 1921px) {
    padding: 15px 170px 15px 200px;
  }

  @include vp-1279 {
    padding: 15px 3.5%;
  }

  @include vp-767 {
    display: grid;
    grid-template-columns: 6fr 1fr;
    gap: 10px 0;
    padding: 15px 20px;
  }
}

.header__logo-link-wrapper {
  margin: 0;

  @include vp-767 {
    max-width: 110px;
  }
}

.header__logo-link-wrapper img {
  @include vp-1279 {
    width: 108px;
    height: 64px;
  }

  @include vp-767 {
    width: 100px;
    height: 58px;
  }
}

.header__burger-button {
  display: none;

  @include vp-767 {
    display: block;
    width: 40px;
    height: 26px;
    background-color: transparent;
    border-left: none;
    border-right: none;
    border-top: 3px solid $color-default-black;
    position: relative;
    cursor: pointer;
    transition: border-top 0.3s ease;

    &::after {
      content: "";
      display: block;
      width: 40px;
      height: 3px;
      position: absolute;
      left: 0;
      top: 100%;
      background-color: $color-default-black;
      transition: transform 0.3s ease;
    }

    &::before {
      content: "";
      display: block;
      width: 40px;
      height: 3px;
      position: absolute;
      left: 0;
      top: 46%;
      background-color: $color-default-orange;
      transition: transform 0.3s ease, background-color 0.3s ease;
    }
  }
}

.header__menu {
  @include vp-767 {
    background-color: $color-default-white;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0 0 0;
    position: absolute;
    top: 87px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    grid-column: 1 / -1;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    overflow-y: scroll;
  }
}

.header.is-open {
  & .header__menu {
    @include vp-767 {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }
  }

  & .header__burger-button {
    @include vp-767 {
      border-top: none;
      border-bottom: none;
      height: 40px;

      &::after {
        content: "";
        width: 50px;
        transform: rotate(-45deg);
        left: -5px;
        top: 20px;
        background-color: $color-default-black;
      }

      &::before {
        content: "";
        display: block;
        transform: rotate(45deg);
        width: 50px;
        height: 3px;
        position: absolute;
        left: -5px;
        top: 20px;
        background-color: $color-default-black;
      }
    }
  }
}

.header__list {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;

  @include vp-767 {
    width: 100%;
    display: grid;
  }
}

.header__item {
  max-width: 150px;
  text-align: center;
  padding: 5px 0 5px 0;
  border-bottom: 2px solid transparent;

  @include vp-1279 {
    max-width: 130px;
  }

  @include vp-767 {
    max-width: none;
    padding: 10px 0 15px 0;
    border-bottom: 1px solid #eeeeee;
  }

  &:not(:last-child) {
    margin: 0 20px 0 0;

    @include vp-767 {
      margin: 0;
    }
  }

  &--active {
    border-bottom: 2px solid $color-default-orange;
  }
}

.header__link {
  font-family: $ff-tilda-sans;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  transition: color $trans-default;

  @include vp-1279 {
    font-size: 16px;
    line-height: 18px;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include hover {
    color: $color-default-orange;
  }

  @include active {
    color: $color-default-orange;
    opacity: 0.8;
  }

  &--active {
    @include hover {
      color: $color-default-black;
      cursor: default;
    }

    @include active {
      color: $color-default-black;
      opacity: 1;
    }

    @include vp-767 {
      color: $color-default-orange;

      @include hover {
        color: $color-default-orange;
        cursor: default;
      }

      @include active {
        color: $color-default-orange;
        opacity: 1;
      }
    }
  }
}

.header__contacts {
  max-width: 600px;
  margin: 0 10px 0 0;
  display: flex;
  flex-direction: column;

  @include vp-1279 {
    display: none;
  }
}

.header__contacts a {
  transition: color $trans-default;

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include hover {
    color: $color-default-orange;
  }

  @include active {
    color: $color-default-orange;
    opacity: 0.8;
    outline: none;
  }
}

.header__contacts a:not(:last-child) {
  margin: 0 15px 0 0;
}

.header__icon {
  position: relative;
  top: 5px;
}

.header__email {
  display: inline-block;
  vertical-align: baseline;
  color: $color-default-black;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  padding: 5px 0 5px 5px;
  margin-bottom: 7px;
  transition: color $trans-default;
}

.header__phone {
  color: $color-default-black;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  padding: 10px 0 10px 2px;
  transition: color $trans-default;
}

.header__contacts a:hover .header__email {
  color: $color-default-orange;
}

.header__contacts a:hover .header__phone {
  color: $color-default-orange;
}

.header > div > a {
  display: block;
  padding: 10px 16px 12px 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid $color-default-black;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  max-width: 170px;
  transition: color $trans-default, border $trans-default;

  @include vp-1279 {
    display: none;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include hover {
    border: 2px solid $color-default-orange;
    color: $color-default-orange;
  }

  @include active {
    opacity: 0.8;
    outline: none;
  }
}
