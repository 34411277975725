.swing-welcome {
  width: 100vw;
  min-width: 1280px;
  background-color: #eeeeee;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.swing-welcome > div {
  width: 100%;
  margin: 70px auto 0 auto;
  max-width: 1920px;
  padding: 80px 15% 80px 10%;
  display: flex;

  @media (min-width: 1921px) {
    padding: 100px 200px 80px 280px;
  }

  @include vp-1279 {
    padding: 40px 6.5% 80px 6.5%;
    margin: 0 auto;
  }

  @include vp-767 {
    padding: 120px 8.5% 40px 8.5%;
  }
}

.swing-welcome h2 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 56px;
  line-height: 66px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 40px 0;

  @include vp-1279 {
    font-size: 48px;
    line-height: 58px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 16px 0;
  }
}

.swing-welcome h3 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;

  @include vp-1279 {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 22px;
    line-height: 30px;
    margin: 0 0 10px 0;
  }
}

.swing-welcome p {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 20px 0;

  @include vp-1279 {
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 15px 0;
  }
}

.swing-welcome a {
  display: inline-block;
  vertical-align: baseline;
  color: $color-default-white;
  font-family: $ff-tilda-sans;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 16px 26px 18px 26px;
  background-color: $color-default-orange;
  border-radius: 3px;
  user-select: none;
  transition: background-color $trans-default;

  @include vp-1279 {
    margin: 0 0 0 45px;
    padding: 17px 27px;
  }

  @include vp-767 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 35px;
    margin: 0;
  }

  &:hover {
    background-color: $color-active-orange;
  }

  &:active {
    background-color: $color-active-orange;
    opacity: 0.9;
  }
}

.swing-welcome__container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;

  @include vp-1279 {
    display: block;
  }
}

.swing-welcome__container img {
  margin: 0;

  @include vp-1279 {
    margin: 0 auto;
    width: 40vw;
    height: 300px;
  }

  @include vp-767 {
    width: 80vw;
    height: 300px;
  }
}

.swing-welcome__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.swing-welcome__item {
  margin: 0 0 20px 0;
}

.swing-welcome__item > p {
  font-size: 20px;
  line-height: 24px;
}
