.about {
  width: 100vw;
  min-width: 1280px;
  background-color: #eeeeee;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.about > div {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 80px 10% 80px 10%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 17%;

  @media (min-width: 1921px) {
    padding: 60px 200px 80px 200px;
  }

  @include vp-1279 {
    display: block;
    padding: 25px 6% 60px 9%;
  }

  @include vp-767 {
    padding: 15px 7.81% 50px 7.81%;
  }
}

.about h3 {
  font-size: 62px;
  line-height: 76px;
  margin: 0 0 15px 0;

  @include vp-1279 {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 23px 0;
  }

  @include vp-767 {
    margin: 0 0 15px 0;
    font-size: 28px;
    line-height: 39px;
  }
}

.about__description p {
  font-size: 26px;
  line-height: 34px;
  margin: 0 0 15px 0;

  @include vp-1279 {
    font-size: 22px;
    line-height: 30px;
  }

  @include vp-767 {
    font-size: 14px;
    line-height: 19px;
  }
}

// Анимация двери

.about__logo-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  padding: 10px 5% 10px 5%;

  @include vp-1279 {
    display: none;
  }
}

.about__logo-door {
  width: 300px;
  height: 300px;
  background-color: #eeeeee;
  border: 5px solid #000000;
  border-radius: 50%;
  position: relative;
}

.about__logo-mask {
  width: 180px;
  height: 310px;
  background-color: #eeeeee;
  margin: -5px auto 0 auto;
}

.about__logo-inside {
  width: 5px;
  height: 120px;
  background-color: #000000;
  position: absolute;
  top: 15px;
  left: calc(50% - 2px);

  &::after {
    content: "";
    display: block;
    width: 5px;
    height: 120px;
    background-color: #000000;
    transform: rotate(60deg);
    position: absolute;
    top: 105px;
    right: 62px;
  }

  &::before {
    content: "";
    display: block;
    width: 5px;
    height: 120px;
    background-color: #000000;
    transform: rotate(-60deg);
    position: absolute;
    top: 105px;
    left: 62px;
  }

  animation-name: door-swing;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-delay: 0;
  animation-fill-mode: forwards;
  transform-origin: 51% 108% 0;
  animation-timing-function: ease;
  transform: rotate(30deg);
}


@keyframes door-swing {
  from {
    transform: rotate(30deg);
  }

  to {
    transform: rotate(-330deg);
  }
}
