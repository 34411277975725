.footer-navigation {
  @include vp-767 {
    width: 100%;
    border-bottom: 1px solid $color-default-orange;
    position: relative;
  }
}

.footer-navigation > h3 {
  font-size: 15px;
  line-height: 16px;
  text-transform: uppercase;
  color: $color-default-white;
  margin: 0 0 20px 0;
  grid-column: 1 / -1;

  @include vp-767 {
    padding: 20px 20px 20px 8%;
    margin: 0;
  }
}

.footer-navigation__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 50px;

  @include vp-1279 {
    grid-template-columns: 1fr;
    gap: 10px 0;
  }

  @include vp-767 {
    padding: 0 25px 0 8%;
    margin: 0;
    width: 280px;
  }
}

.footer-navigation__list:last-child {
  @include vp-767 {
    margin: 0 0 15px 0;
  }
}

.footer-navigation__item {
  min-width: 200px;

  @include vp-767 {
    min-width: 107%;
    margin-right: 23.5%;
    margin-bottom: 5px;
  }
}

.footer-navigation__item > a {
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.7);
  transition: color $trans-default;

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include hover {
    color: $color-default-orange;
  }

  @include active {
    color: $color-default-orange;
    opacity: 0.9;
  }
}
