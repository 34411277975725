.swing-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
  padding: 20px;
  border-bottom: 2px solid #eeeeee;

  @include vp-767 {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.swing-card__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.swing-card__item {
  margin: 0;
  padding: 0 0 0 15px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    transform: rotate(45deg);
    width: 6px;
    height: 6px;
    background-color: $color-default-orange;
    left: -1px;
    top: 8px;
  }
}

.swing-card__item > p {
  margin: 0 0 8px 0;
  font-size: 18px;
  line-height: 22px;
}

.swing-card__name {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 30px;
  line-height: 36px;
  margin: 0 0 20px 0;

  @include vp-1279 {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 30px 0;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 15px 0;
    text-align: center;
  }
}

.swing-card img {
  @include vp-767 {
    margin: 0 auto;
  }
}
