.window-catalog {
  width: 100vw;
  min-width: 1280px;
  background-color: #eeeeee;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.window-catalog > div {
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  padding: 60px 10% 60px 10%;

  @media (min-width: 1921px) {
    padding: 80px 200px 80px 280px;
  }

  @include vp-1279 {
    padding: 60px 6.5% 60px 6.5%;
    margin: 0 auto;
  }

  @include vp-767 {
    padding: 60px 8.5% 40px 8.5%;
  }
}

.window-catalog h2 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 60px;
  line-height: 76px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 40px 0;

  @include vp-1279 {
    font-size: 54px;
    line-height: 70px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 8px 0;
  }
}

.window-catalog__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  list-style-type: none;
  margin: 0 auto;
  padding: 20px;

  @include vp-1279 {
    grid-template-columns: 1fr 1fr;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
  }
}

.window-catalog__item {
  padding: 20px;
  background-color: $color-default-white;
  border-radius: 15px;
  box-shadow: 0 0 15px #aeaeae;
}

.window-catalog__item div {
  border-top: 2px solid $color-default-orange;
  padding: 15px 0 0 0;
}

.window-catalog__item img {
  margin: 0 auto 30px auto;
}

.window-catalog__name {
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  margin: 0;

  @include vp-1279 {
    font-size: 20px;
    line-height: 34px;
  }
}
