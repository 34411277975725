.main-container {
  width: 100vw;
  min-width: 1280px;
  margin: 0 auto;
  flex-grow: 1;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}
