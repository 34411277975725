.advantages {
  width: 100vw;
  min-width: 1280px;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.advantages > div {
  width: 100%;
  max-width: 1920px;
  padding: 100px 18% 100px 18.2%;
  margin: 0 auto;

  @media (min-width: 1921px) {
    padding: 100px 345px 100px 350px;
  }

  @include vp-1279 {
    padding: 80px 6.5% 60px 6.5%;
  }

  @include vp-767 {
    padding: 50px 7.81% 30px 7.81%;
  }
}

.advantages__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @include vp-767 {
    display: flex;
    flex-direction: column;
  }
}

.advantages__item {
  border-left: 5px solid $color-default-orange;
  padding: 40px 40px 40px 40px;
  color: $color-default-orange;
  background-color: #eeeeee;
  min-height: 250px;

  @include vp-1279 {
    padding: 30px 30px 30px 30px;
    min-height: 210px;
  }

  @include vp-767 {
    padding: 25px 30px 35px 25px;
  }
}

.advantages__icon {
  margin: 0 0 15px 0;
  transition: color $trans-default;

  @include vp-1279 {
    margin: 0 0 8px 0;
  }
}

.advantages__item > p {
  font-family: $ff-tilda-sans;
  color: $color-default-black;
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 15px 0;
  transition: color $trans-default;

  @include vp-1279 {
    margin: 0 0 11px 0;
    font-size: 20px;
    line-height: 24px;
  }

  @include vp-767 {
    font-size: 20px;
    line-height: 28px;
  }
}

.advantages__item-description-wrapper > p {
  font-size: 16px;
  line-height: 22px;
  color: $color-default-black;
  margin: 0;
  transition: color $trans-default;

  @include vp-767 {
    font-size: 14px;
    line-height: 19px;
  }
}
