.page404 {
  width: 100vw;
  min-width: $vp-1024;
  background-color: #eeeeee;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.page404 > div {
  width: 100%;
  margin: 100px auto 0 auto;
  max-width: 1920px;
  padding: 150px 15% 158px 10%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1921px) {
    padding: 250px 200px 278px 280px;
  }

  @include vp-1279 {
    padding: 180px 6.5% 204px 6.5%;
    margin: 0 auto;
  }

  @include vp-767 {
    padding: 200px 8.5% 190px 8.5%;
  }
}

.page404 h3 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 70px;
  line-height: 84px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 40px 0;

  @include vp-1279 {
    font-size: 54px;
    line-height: 66px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 15px 0;
  }
}

.page404 p {
  font-size: 40px;
  line-height: 44px;

  @include vp-1279 {
    font-size: 30px;
    line-height: 36px;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 28px;
  }
}

.page404 a {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 30px;
  line-height: 34px;
  transition: color $trans-default;

  &:hover {
    color: $color-default-orange;
  }

  &:active {
    color: $color-default-orange;
    opacity: 0.8;
  }

  @include vp-1279 {
    font-size: 24px;
    line-height: 30px;
  }

  @include vp-767 {
    font-size: 18px;
    line-height: 22px;
  }
}
