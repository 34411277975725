.swing-catalog {
  width: 100vw;
  min-width: 1280px;
  background-color: $color-default-white;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.swing-catalog > div {
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  padding: 60px 10% 60px 10%;

  @media (min-width: 1921px) {
    padding: 80px 200px 80px 100px;
  }

  @include vp-1279 {
    padding: 60px 6.5% 30px 6.5%;
    margin: 0 auto;
  }

  @include vp-767 {
    padding: 50px 8.5% 40px 8.5%;
  }
}

.swing-catalog h2 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 50px;
  line-height: 66px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 40px 0;

  @include vp-1279 {
    font-size: 48px;
    line-height: 58px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 8px 0;
  }
}

.swing-catalog__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.swing-catalog__item {
  margin: 0 0 30px 0;
}
