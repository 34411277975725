.welcome {
  width: 100vw;
  min-width: 1280px;
  background-color: #1f2227;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75)), url("../img/welcome.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media (min-resolution: 2dppx) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.75)), url("../img/welcome@2x.webp");
  }

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.welcome > div {
  width: 100%;
  margin: 0 auto 0 auto;
  max-width: 1920px;
  padding: 220px 15% 120px 10%;
  display: flex;

  @media (min-width: 1921px) {
    padding: 200px 200px 120px 280px;
  }

  @include vp-1279 {
    padding: 110px 6.5% 110px 6.5%;
    margin: 0 auto;
  }

  @include vp-767 {
    padding: 170px 8.5% 80px 8.5%;
  }
}

.welcome h2 {
  color: $color-default-white;
  font-family: $ff-tilda-sans;
  font-size: 60px;
  line-height: 80px;
  font-weight: 700;
  margin: 0 0 40px 0;

  @include vp-1279 {
    font-size: 64px;
    line-height: 78px;
    margin: 0;
  }

  @include vp-767 {
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 8px 0;
  }
}

.welcome p {
  color: $color-default-white;
  font-family: $ff-tilda-sans;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-transform: lowercase;
  margin: 0 0 50px 0;
  max-width: 680px;

  @include vp-1279 {
    margin: 0 0 58px 0;
  }

  @include vp-767 {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 45px 0;
  }
}

.welcome a {
  display: inline-block;
  vertical-align: baseline;
  color: $color-default-white;
  font-family: $ff-tilda-sans;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 16px 26px 18px 26px;
  background-color: $color-default-orange;
  border-radius: 3px;
  user-select: none;
  transition: background-color $trans-default;

  @include vp-1279 {
    margin: 0;
    padding: 17px 27px;
  }

  @include vp-767 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 30px;
    margin: 0;
    letter-spacing: 0;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include hover {
    background-color: $color-active-orange;
  }

  @include active {
    background-color: $color-active-orange;
    opacity: 0.9;
  }
}
