.privacy {
  width: 100vw;
  min-width: 1280px;
  background-color: $color-default-white;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.privacy > div {
  width: 100%;
  margin: 100px auto 0 auto;
  max-width: 1920px;
  padding: 100px 15% 80px 10%;

  @media (min-width: 1921px) {
    padding: 250px 200px 278px 280px;
  }

  @include vp-1279 {
    padding: 60px 6.5% 50px 6.5%;
    margin: 0 auto;
  }

  @include vp-767 {
    padding: 120px 8.5% 30px 8.5%;
  }
}

.privacy h2 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  text-align: left;
  margin: 0 0 40px 0;

  @include vp-1279 {
    font-size: 36px;
    line-height: 48px;
    margin: 0;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 8px 0;
  }
}

.privacy__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.privacy__title {
  font-size: 30px;
  line-height: 36px;

  @include vp-1279 {
    font-size: 28px;
    line-height: 34px;
  }

  @include vp-767 {
    font-size: 20px;
    line-height: 26px;
  }
}

.privacy__item p {
  font-size: 22px;
  line-height: 30px;

  @include vp-1279 {
    font-size: 20px;
    line-height: 28px;
  }

  @include vp-767 {
    font-size: 16px;
    line-height: 20px;
  }
}
