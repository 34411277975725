.address {
  width: 100vw;
  min-width: 1280px;
  background-color: #eeeeee;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.address > div {
  width: 100%;
  max-width: 1920px;
  padding: 100px 15% 100px 15%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media (min-width: 1921px) {
    padding: 100px 345px 100px 350px;
  }

  @include vp-1279 {
    padding: 80px 6% 60px 6%;
    gap: 20px;
  }

  @include vp-767 {
    padding: 50px 7.81% 30px 7.81%;
    display: flex;
    flex-direction: column;
  }
}

.address__wrapper {
  padding: 40px 0;

  @include vp-1279 {
    padding: 30px 0;
  }

  @include vp-767 {
    padding: 20px 0;
  }
}

.address h4 {
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 15px 0;

  @include vp-1279 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 10px 0;
  }

  @include vp-767 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 5px 0;
  }
}

.address p {
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 15px 0;

  @include vp-1279 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px 0;
  }

  @include vp-767 {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 5px 0;
  }
}

.address__wrapper a {
  display: block;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 15px 0;
  transition: color $trans-default;
  max-width: 200px;

  @include vp-1279 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px 0;
    max-width: 200px;
  }

  @include vp-767 {
    max-width: 150px;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 5px 0;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include hover {
    color: $color-default-orange;
  }

  @include active {
    color: $color-default-orange;
    opacity: 0.9;
  }
}
