.reference {
  width: 100vw;
  min-width: 1280px;
  background-color: $color-default-white;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.reference > div {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 80px 10% 60px 10%;

  @media (min-width: 1921px) {
    padding: 115px 200px 80px 200px;
  }

  @include vp-1279 {
    display: block;
    padding: 25px 6% 50px 9%;
  }

  @include vp-767 {
    padding: 25px 7.81% 80px 7.81%;
  }
}

.reference h3 {
  font-size: 48px;
  line-height: 62px;
  margin: 0 0 30px 0;
  text-align: center;

  @include vp-1279 {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 30px;
    line-height: 36px;
  }
}

.reference p {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 20px 0;

  @include vp-1279 {
    font-size: 18px;
    line-height: 22px;
  }
}

.reference__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 30px;

  @include vp-1279 {
    grid-template-columns: 1fr 1fr;
    gap: 20px 30px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
  }
}

.reference__img-wrapper--tablet {
  @include vp-1279 {
    grid-column: 1 / -1;
  }
}

.reference__img-wrapper img {
  margin: 0;

  @include vp-1279 {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
