.cookies {
  display: none;
  align-items: center;
  left: 25px;
  bottom: 25px;
  width: 300px;
  padding: 25px 20px;
  margin: 20px 0 20px 20px;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(18, 18, 28, 0.37);
  background-color: #ffffff;

  &.is-active {
    display: inline-flex;
    flex-direction: column;
    position: fixed;
    z-index: 5;
  }

  &__text {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 10px 0;
  }

  &__btn {
    align-self: center;
    padding: 10px 15px;
    color: #ffffff;
    width: 40%;
    border: 0;
    border-radius: 4px;
    background-color: $color-default-orange;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: #ff9900;
    }

    &:active {
      background-color: #ff9900;
      opacity: 0.9;
    }
  }

  a {
    text-decoration: underline;
  }
}
