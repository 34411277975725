@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Tilda Sans";

  font-display: swap;
  src:
    url("../fonts/TildaSans-Regular.woff2") format("woff2"),
    url("../fonts/TildaSans-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Tilda Sans";

  font-display: swap;
  src:
    url("../fonts/TildaSans-Bold.woff2") format("woff2"),
    url("../fonts/TildaSans-Bold.woff") format("woff");
}
