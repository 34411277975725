.tech {
  width: 100vw;
  min-width: 1280px;
  background-color: #eeeeee;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.tech > div {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 115px 10% 80px 10%;

  @media (min-width: 1921px) {
    padding: 115px 200px 80px 200px;
  }

  @include vp-1279 {
    display: block;
    padding: 25px 6% 50px 9%;
  }

  @include vp-767 {
    padding: 25px 7.81% 80px 7.81%;
  }
}

.tech h3 {
  font-size: 62px;
  line-height: 76px;
  margin: 0 0 35px 0;
  text-align: center;

  @include vp-1279 {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 30px;
    line-height: 36px;
  }
}

.tech__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 50px;

  @include vp-1279 {
    grid-template-columns: 1fr;
  }
}

.tech__img-wrapper img {
  margin: 30px 0 0 0;

  @include vp-1279 {
    width: 350px;
    height: 350px;
    margin: 0 auto 20px auto;
  }
}

.tech__description {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  margin: 0 0 20px 0;

  @include vp-1279 {
    font-size: 28px;
    line-height: 36px;
  }
}

.tech__list {
  list-style-type: none;
  margin: 0 0 10px 10px;
  padding: 0;
}

.tech__item {
  padding: 0 0 0 15px;
  position: relative;

  &:not(:last-child) {
    margin: 0 0 7px 0;

    @include vp-1279 {
      margin: 0 0 10px 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    transform: rotate(45deg);
    width: 6px;
    height: 6px;
    background-color: $color-default-orange;
    left: -1px;
    top: 8px;
  }
}

.tech__item p {
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  @include vp-1279 {
    font-size: 18px;
    line-height: 22px;
  }
}

.tech__subtitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 20px 0;

  @include vp-1279 {
    font-size: 20px;
    line-height: 24px;
  }
}
