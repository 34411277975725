.activities {
  width: 100vw;
  min-width: 1280px;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.activities > div {
  width: 100%;
  max-width: 1920px;
  padding: 180px 15% 100px 15%;
  margin: 0 auto;

  @media (min-width: 1921px) {
    padding: 180px 345px 100px 350px;
  }

  @include vp-1279 {
    padding: 20px 6.5% 50px 6.5%;
  }

  @include vp-767 {
    padding: 110px 7.8% 30px 7.8%;
  }
}

.activities__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 0 0 50px 0;

  @include vp-767 {
    display: flex;
    flex-direction: column;
  }
}

.activities__text-wrapper h3 {
  font-size: 30px;
  line-height: 36px;
  margin: 0 0 15px 0;

  @include vp-1279 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 10px 0;
  }

  @include vp-767 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 5px 0;
  }
}

.activities__text-wrapper p {
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 15px 0;

  @include vp-1279 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px 0;
  }

  @include vp-767 {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 5px 0;
  }
}

.activities__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @include vp-767 {
    display: flex;
    flex-direction: column;
  }
}

.activities__item {
  border-left: 5px solid $color-default-orange;
  padding: 40px 40px 40px 40px;
  color: $color-default-orange;
  background-color: #eeeeee;
  min-height: 250px;

  @include vp-1279 {
    padding: 20px 20px 20px 20px;
    min-height: 210px;
  }

  @include vp-767 {
    padding: 25px 30px 35px 25px;
    min-height: 150px;
  }
}

.activities__item > p {
  font-family: $ff-tilda-sans;
  color: $color-default-black;
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 15px 0;
  transition: color $trans-default;

  @include vp-1279 {
    margin: 0 0 11px 0;
    font-size: 20px;
    line-height: 24px;
  }

  @include vp-767 {
    font-size: 20px;
    line-height: 28px;
  }
}

.activities__item-description-wrapper > p {
  font-size: 16px;
  line-height: 22px;
  color: $color-default-black;
  margin: 0;
  transition: color $trans-default;

  @include vp-767 {
    font-size: 14px;
    line-height: 19px;
  }
}
