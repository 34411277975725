.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: rgba($color-default-black, 0.8);

  &--close {
    display: none;
  }
}

.modal > div {
  position: relative;
  background-color: #808080;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 500px;
  min-height: 610px;
  padding: 40px;
  margin: 0 auto;

  @include vp-767 {
    width: 100vw;
    padding: 40px 20px;
  }
}

.modal h2 {
  color: $color-default-white;
  font-family: $ff-tilda-sans;
  font-weight: 400;
  font-size: 30px;
  line-height: 42px;
  margin: 0;
}

.modal p {
  font-family: $ff-tilda-sans;
  color: $color-default-white;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 40px 0;
  width: 380px;

  @media (max-width: $vp-767) {
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 35px 0;
    width: 90%;
  }
}

.modal__form-wrapper fieldset {
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  flex-direction: column;
}

.modal__label-wrapper input {
  font-family: $ff-tilda-sans;
  color: $color-default-white;
  border: none;
  width: 420px;
  height: 50px;
  background-color: rgba(4, 20, 39, 0.15);
  border-radius: 3px;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px 0;

  &::placeholder {
    color: $color-default-white;
    opacity: 0.4;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @media (max-width: $vp-767) {
    width: 100%;
  }
}

.modal__label-wrapper--textarea textarea {
  font-family: $ff-tilda-sans;
  border: none;
  width: 420px;
  min-height: 155px;
  background-color: rgba(4, 20, 39, 0.15);
  border-radius: 3px;
  padding: 22px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px 0;
  color: $color-default-white;

  &::placeholder {
    color: $color-default-white;
    opacity: 0.4;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @media (max-width: $vp-767) {
    width: 100%;
    padding: 25px 15px;
    min-height: 180px;
    margin-bottom: 10px;
  }
}

.modal__label-wrapper {
  position: relative;
}

.modal__button {
  font-family: $ff-tilda-sans;
  display: inline-block;
  vertical-align: baseline;
  padding: 14px 57px 12px 57px;
  max-width: 465px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-default-white;
  border: none;
  background-color: $color-default-orange;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  margin: 0 0 15px 0;

  @media (max-width: $vp-767) {
    max-width: 100%;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
    outline-offset: 3px;
  }

  @include hover {
    background-color: $color-active-orange;
  }

  @include active {
    background-color: $color-active-orange;
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.6;
    background-color: $color-active-orange;
    cursor: auto;
  }
}

.modal > div > button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 28px;
    height: 2px;
    border-radius: 5px;
    background-color: $color-default-white;
    transform: translate(6px, -1px) rotate(45deg);
  }

  &::before {
    content: "";
    display: block;
    width: 28px;
    height: 2px;
    border-radius: 5px;
    background-color: $color-default-white;
    transform: translate(6px, 1px) rotate(-45deg);
  }

  @include hover {
    &::after {
      background-color: $color-default-orange;
    }

    &::before {
      background-color: $color-default-orange;
    }
  }

  @include active {
    &::after {
      background-color: $color-default-orange;
      opacity: 0.9;
    }

    &::before {
      background-color: $color-default-orange;
      opacity: 0.9;
    }
  }
}

.modal__checkbox-mark {
  display: inline-block;
  vertical-align: baseline;
  position: absolute;
  border: 1px solid $color-default-white;
  border-radius: 3px;
  width: 15px;
  height: 15px;
  top: 5px;
  left: 10px;
  cursor: pointer;
}

.modal__label-wrapper input:checked + .modal__checkbox-mark {
  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 7px;
    border-bottom: 1px solid $color-default-white;
    border-right: 1px solid $color-default-white;
    transform: translate(4px, 1px) rotate(45deg);
  }
}

.modal__label-wrapper input:focus + .modal__checkbox-mark {
  outline: 2px solid $color-default-orange;
}

.modal__checkbox-text {
  display: inline-block;
  vertical-align: baseline;
  font-family: $ff-tilda-sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $color-default-white;
  opacity: 0.9;
  padding: 0 0 0 35px;
  cursor: pointer;

  @media (max-width: $vp-767) {
    top: 5px;
    left: -5px;
  }
}

.modal__label-wrapper:hover .modal__checkbox-text {
  opacity: 1;
}

form._sending .modal__button {
  background-color: $color-default-orange;
}
