.window-welcome {
  width: 100vw;
  min-width: $vp-1024;

  @include vp-1279 {
    min-width: 768px;
  }

  @include vp-767 {
    min-width: 320px;
  }
}

.window-welcome > div {
  width: 100%;
  margin: 50px auto 0 auto;
  max-width: 1920px;
  padding: 100px 15% 20px 10%;
  display: flex;

  @media (min-width: 1921px) {
    padding: 150px 200px 80px 280px;
  }

  @include vp-1279 {
    padding: 30px 6.5% 20px 6.5%;
    margin: 0 auto;
  }

  @include vp-767 {
    padding: 110px 8.5% 20px 8.5%;
  }
}

.window-welcome h2 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 56px;
  line-height: 66px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 40px 0;

  @include vp-1279 {
    font-size: 48px;
    line-height: 58px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 16px 0;
  }
}

.window-welcome h4 {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 38px;
  line-height: 52px;
  font-weight: 700;
  margin: 0 0 30px 0;

  @include vp-1279 {
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 20px 0;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 16px 0;
  }
}

.window-welcome p {
  color: $color-default-black;
  font-family: $ff-tilda-sans;
  font-size: 28px;
  line-height: 34px;
  font-weight: 400;
  margin: 0 0 20px 0;

  @include vp-1279 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 15px 0;
  }

  @include vp-767 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 10px 0;
  }
}

.window-welcome img {
  margin: 0 auto;
}
