.footer-contacts {
  width: 23.3%;

  @include vp-767 {
    width: 100%;
    border-bottom: 1px solid $color-default-orange;
    position: relative;
  }
}

.footer-contacts > h3 {
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 20px 0;

  @include vp-767 {
    padding: 20px 177px 20px 8%;
    margin: 0;
  }
}

.footer-contacts__wrapper > p {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 10px 0;
  color: rgba(255, 255, 255, 0.7);

  @include vp-1279 {
    margin: 0 0 8px 0;
  }

  @include vp-767 {
    padding: 0 25px 0 8%;
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 18px 0;
  }
}

.footer-contacts__link {
  display: block;
  font-size: 16px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.7);
  max-width: 180px;
  margin: 0 0 5px 0;

  @include vp-767 {
    padding: 0 15px 0 8%;
    font-size: 14px;
    max-width: 230px;
    margin: 0 0 15px 0;
  }

  @include focus {
    outline: 2px solid $color-default-orange;
  }

  @include hover {
    color: $color-default-orange;
  }

  @include active {
    color: $color-default-orange;
    opacity: 0.9;
  }
}
